<template>
  <div class="bg-white">
    <div class="text-justify text-wrap pa-10">
      <div>
        <div class="d-flex justify-space-between align-center mb-5">
          <div class="headline">
            PRIVACY POLICY
          </div>
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          NAME AND ADDRESS OF THE PERSON RESPONSIBLE FOR THE PROCESSING
        </h5>
        <div>
          The person responsible within the meaning of the basic data protection regulation, other data protection laws
          applicable in the member states of the European Union and other provisions of a data protection nature is:
          <br /><br />
          PW-BWS GmbH<br />
          Lindwurmstr. 101<br />
          80337 Munich<br />
          Deutschland<br />
          Tel.: +49 89 66011829-0<br />
          E-Mail:
          <a href="mailto:datenschutz@pw-bws.com" class="font-500 text-underline color-primary"
            >datenschutz@pw-bws.com</a
          ><br />
          Website: www.pw-bws.com<br />
        </div>
        <h5 class="mt-5 headline-bold" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          COOKIES
        </h5>
        <div class="my-5">
          The internet pages of MICELOC use cookies. Cookies are text files which are stored on a computer system via an
          Internet browser.<br /><br />

          Numerous Internet pages and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a
          unique identification of the cookie. It consists of a string of characters which can be used to assign
          Internet pages and servers to the specific Internet browser in which the cookie was stored. This enables the
          Internet pages and servers visited to distinguish the individual browser of the person concerned from other
          Internet browsers that contain other cookies. A specific Internet browser can be recognized and identified by
          means of the unique cookie ID.<br /><br />

          Through the use of cookies, MICELOC can provide users of this website with more user-friendly services that
          would not be possible without the setting of cookies.<br /><br />

          By means of a cookie, the information and offers on our website can be optimised in the interest of the user.
          Cookies enable us, as already mentioned, to recognize the users of our website. The purpose of this
          recognition is to make it easier for users to use our website. For example, the user of a website that uses
          cookies does not have to enter his or her access data each time he or she visits the website, because this is
          done by the website and the cookie stored on the user’s computer system. Another example is the cookie of a
          shopping cart in the online shop. The online shop uses a cookie to remember the articles that a customer has
          placed in the virtual shopping cart.<br /><br />

          The person concerned can prevent the setting of cookies by our website at any time by means of a corresponding
          setting of the Internet browser used and thus permanently object to the setting of cookies. Furthermore,
          cookies that have already been set can be deleted at any time via an Internet browser or other software
          programs. This is possible in all common Internet browsers. If the person concerned deactivates the setting of
          cookies in the Internet browser used, it is possible that not all functions of our website can be used to
          their full extent.<br /><br />
        </div>
        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          COLLECTION OF GENERAL DATA AND INFORMATION
        </h5>
        <div class="my-5">
          The website of MICELOC collects a number of general data and information with every call of the website by a
          person concerned or an automated system. These general data and information are stored in the log files of the
          server. The following can be recorded: (1) the browser types and versions used, (2) the operating system used
          by the accessing system, (3) the website from which an accessing system accesses our website (so-called
          referrer), (4) the sub-websites that are accessed via an accessing system on our website, (5) the date and
          time of an access to the Internet site, (6) an Internet protocol address (IP address), (7) the Internet
          service provider of the accessing system and (8) other similar data and information which serve to prevent
          danger in the event of attacks on our information technology systems.<br /><br />
          When using these general data and information, MICELOC does not draw any conclusions about the person
          concerned. Rather, this information is required in order to (1) deliver the contents of our website correctly,
          (2) optimize the contents of our website as well as the advertising for it, (3) ensure the permanent
          operability of our information technology systems and the technology of our website, and (4) to provide law
          enforcement agencies with the information necessary for prosecution in case of a cyber attack. These
          anonymously collected data and information are therefore statistically evaluated by MICELOC on the one hand
          and also with the aim of increasing data protection and data security in our company, in order to ultimately
          ensure an optimal level of protection for the personal data processed by us. The anonymous data of the server
          log files are stored separately from all personal data provided by an affected person.
        </div>
        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          REGISTRATION ON OUR WEBSITE
        </h5>
        <div class="my-5">
          The data subject shall have the possibility to register on the Internet site of the controller by providing
          personal data. The personal data transmitted to the data controller is specified in the respective input mask
          used for registration. The personal data entered by the data subject are collected and stored solely for
          internal use by the data controller and for his own purposes. The data controller may arrange for the transfer
          to one or more processors, such as a parcel service provider, who shall also use the personal data exclusively
          for internal use attributable to the controller.<br /><br />
          Furthermore, by registering on the controller’s website, the IP address assigned to the data subject by the
          Internet Service Provider (ISP), the date and time of registration are stored. This data is stored with the
          aim of preventing the misuse of our services and, if necessary, to enable the investigation of criminal
          offences. To this extent, the storage of this data is necessary to protect the person responsible for
          processing. As a matter of principle, this data will not be passed on to third parties, unless there is a
          legal obligation to do so or the passing on of the data serves criminal prosecution.<br /><br />
          The registration of the data subject under voluntary disclosure of personal data serves the data controller to
          offer the data subject content or services which, due to the nature of the matter, can only be offered to
          registered users. Registered persons are free to modify the personal data provided during registration at any
          time or to have them completely deleted from the data stock of the data controller.<br /><br />
          The data controller shall at any time upon request provide any data subject with information as to which
          personal data relating to the data subject are stored. In addition, the controller shall correct or delete
          personal data at the request or notification of the data subject, unless otherwise required by law. The entire
          staff of the data controller is available to the data subject as contact persons in this context.<br /><br />
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          SUBSCRIBE TO OUR NEWSLETTER
        </h5>
        <div class="my-5">
          On the MICELOC website, users are given the opportunity to subscribe to our company newsletter. The personal
          data transmitted to the data controller when subscribing to the newsletter can be seen from the input mask
          used for this purpose.<br /><br />
          MICELOC regularly informs its customers and business partners about company offers by means of a newsletter.
          The newsletter of our company can generally only be received by the data subject if (1) the data subject has a
          valid e-mail address and (2) the data subject registers for the newsletter mailing. For legal reasons, a
          confirmation e-mail will be sent to the e-mail address registered for the first time by a person concerned for
          the purpose of receiving the newsletter using the double opt-in procedure. This confirmation mail is used to
          check whether the owner of the e-mail address has authorized the receipt of the newsletter as a data
          subject.<br /><br />
          When registering for the newsletter, we also save the IP address assigned by the Internet Service Provider
          (ISP) of the computer system used by the person concerned at the time of registration as well as the date and
          time of registration. The collection of this data is necessary in order to be able to trace the (possible)
          misuse of the e-mail address of a data subject at a later date and therefore serves to provide legal
          protection for the data controller.<br /><br />
          The personal data collected in the course of registering for the newsletter is used exclusively for sending
          our newsletter. In addition, subscribers to the newsletter could be informed by e-mail if this is necessary
          for the operation of the newsletter service or for registration, as might be the case if there are changes to
          the newsletter offer or if technical conditions change. The personal data collected within the scope of the
          newsletter service will not be passed on to third parties. The subscription to our newsletter can be cancelled
          by the person concerned at any time. The consent to the storage of personal data, which the person concerned
          has given us for the newsletter service, can be revoked at any time. For the purpose of revoking the consent,
          a corresponding link is included in every newsletter. It is also possible to unsubscribe from the newsletter
          at any time directly on the website of the data controller or to inform the data controller in another way.<br /><br />
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          NEWSLETTER TRACKING
        </h5>
        <div class="my-5">
          The newsletters of MICELOC contain so-called counting pixels. A tracking pixel is a miniature graphic embedded
          in such e-mails sent in HTML format to enable log file recording and log file analysis. This enables a
          statistical evaluation of the success or failure of online marketing campaigns. Based on the embedded
          pixel-code, MICELOC can identify whether and when an e-mail was opened by a person concerned and which links
          contained in the e-mail were called up by the person concerned.<br /><br />
          Such personal data collected via the tracking pixels contained in the newsletters are stored and evaluated by
          the data controller in order to optimise the newsletter dispatch and to adapt the content of future
          newsletters even better to the interests of the data subject. This personal data is not passed on to third
          parties. Affected persons are entitled to revoke the separate declaration of consent submitted in this regard
          via the double opt-in procedure at any time. After revocation, these personal data will be deleted by the data
          controller. MICELOC automatically interprets a cancellation of the receipt of the newsletter as a
          revocation.<br /><br />
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          CONTACT POSSIBILITY VIA THE INTERNET SITE
        </h5>
        <div class="my-5">
          Due to legal requirements, the MICELOC website contains information that enables quick electronic contact with
          our company as well as direct communication with us, which also includes a general address for so-called
          electronic mail (e-mail address). If a data subject contacts the data controller by e-mail or via a contact
          form, the personal data transmitted by the data subject is automatically stored. Such personal data
          transmitted voluntarily by a data subject to the controller are stored for the purposes of processing or
          contacting the data subject. Such personal data shall not be disclosed to third parties.<br /><br />
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          ROUTINE DELETION AND BLOCKING OF PERSONAL DATA
        </h5>
        <div class="my-5">
          The controller shall process and store personal data relating to the data subject only for the time necessary
          to achieve the purpose of storage or where provided for by the European Directive and Regulation or by any
          other law or regulation to which the controller is subject.<br /><br />
          If the purpose of storage ceases to apply or if a storage period prescribed by the European Directive and
          Regulation Giver or any other competent legislator expires, the personal data will be blocked or deleted as a
          matter of routine and in accordance with the statutory provisions.<br /><br />
        </div>

        <h5 class="my-5 headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          RIGHTS OF THE DATA SUBJECT
        </h5>

        <h6 class="font-700 f-20 text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          RIGHT TO CONFIRMATION
        </h6>
        <div class="my-5">
          Every data subject has the right, granted by the European Directives and Regulations, to obtain confirmation
          from the controller as to whether personal data relating to him are being processed. If a data subject wishes
          to exercise this right of confirmation, he or she may at any time contact an employee of the controller.<br />
        </div>

        <h6 class="font-700 f-20 text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          RIGHT TO INFORMATION
        </h6>
        <div class="my-5">
          Any person affected by the processing of personal data has the right, granted by the European Directives and
          Regulations, to obtain at any time and free of charge from the controller information on the personal data
          stored in relation to him or her and a copy thereof. Furthermore, the European Directive and Regulation Giver
          has granted the data subject access to the following information:<br /><br />
          <ul>
            <li>the processing purposes</li>
            <li>the categories of personal data processed</li>
            <li>
              the recipients or categories of recipients to whom the personal data have been or will be disclosed, in
              particular to recipients in third countries or to international organisations
            </li>
            <li>
              if possible, the planned duration for which the personal data will be stored or, if this is not possible,
              the criteria for determining this duration
            </li>
            <li>
              the existence of a right of rectification or erasure of personal data relating to them or of a right of
              objection to their processing by the controller
            </li>
            <li>
              the existence of a right of appeal to a supervisory authority
            </li>
            <li>
              if the personal data are not collected from the data subject: All available information on the origin of
              the data
            </li>
            <li>
              the existence of automated decision-making, including profiling, in accordance with Article 22(1) and (4)
              of the DPA and, at least in these cases, meaningful information about the logic involved and the scope and
              intended impact of such processing on the data subject
            </li>
            <li>
              The data subject shall also have the right to obtain information as to whether personal data have been
              transferred to a third country or to an international organisation. If this is the case, the data subject
              shall also have the right to obtain information on the appropriate safeguards in relation to the transfer.
            </li>
          </ul>
          <br />
          If a data subject wishes to exercise this right of access, he or she may at any time contact a member of the
          staff of the controller.
        </div>

        <h6 class="font-700 f-20 text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          RIGHT OF RECTIFICATION
        </h6>
        <div class="my-5">
          Every person concerned by the processing of personal data has the right, granted by the European legislator,
          to request the rectification without delay of inaccurate personal data concerning him. The data subject shall
          also have the right to request the completion of incomplete personal data, including by means of a
          supplementary declaration, having regard to the purposes of the processing.<br /><br />
          If a data subject wishes to exercise this right of rectification, he or she may at any time contact an
          employee of the controller.<br />
        </div>

        <h6 class="font-700 f-20 text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          RIGHT OF CANCELLATION
        </h6>
        <div class="my-5">
          Any person affected by the processing of personal data has the right, granted by the European Directives and
          Regulations, to obtain from the controller the immediate erasure of personal data relating to him/her, where
          one of the following reasons applies and provided that the processing is not necessary:<br /><br />
          <ul>
            <li>
              the personal data have been collected or otherwise processed for purposes for which they are no longer
              necessary
            </li>
            <li>
              The data subject withdraws the consent on which the processing was based pursuant to Article 6(1)(a) of
              the DPA or Article 9(2)(a) of the DPA, and there is no other legal basis for the processing.
            </li>
            <li>
              The data subject lodges an objection to the processing pursuant to Article 21(1) DPA and there are no
              overriding legitimate reasons for the processing, or the data subject lodges an objection to the
              processing pursuant to Article 21(2) DPA.
            </li>
            <li>The personal data were processed unlawfully.</li>
            <li>
              The deletion of the personal data is necessary to comply with a legal obligation under Union law or the
              law of the Member States to which the controller is subject.
            </li>
            <li>
              The personal data were collected in relation to information society services offered in accordance with
              Article 8 (1) DS-GVO.
            </li>
            <li>
              If any of the above reasons apply and a data subject wishes to have personal data stored by MICELOC
              deleted, he/she may at any time contact a member of the staff of the data controller. The MICELOC employee
              will ensure that the request for deletion is complied with immediately.
            </li>
          </ul>
          <br /><br />
          If the personal data have been made public by MICELOC and our company as data controller is obliged to delete
          the personal data pursuant to Art. 17 para. 1 of the DS Block Exemption Regulation, MICELOC shall take
          reasonable measures, including technical measures, taking into account the available technology and the
          implementation costs, to inform other data controllers processing the published personal data that the data
          subject has requested that these other data controllers delete all links to these personal data or copies or
          replications of these personal data, unless the processing is necessary. The MICELOC employee will make the
          necessary arrangements in each individual case.
        </div>

        <h6 class="font-700 f-20 text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          RIGHT TO LIMITATION OF PROCESSING
        </h6>
        <div class="my-5">
          Any person affected by the processing of personal data has the right, granted by the European Directives and
          Regulations, to request the controller to restrict the processing if one of the following conditions is
          met:<br /><br />
          the accuracy of the personal data is contested by the data subject, for a period of time sufficient to enable
          the controller to verify the accuracy of the personal data<br />
          The processing is unlawful, the data subject refuses to have the personal data deleted and instead requests
          the restriction of the use of the personal data. The controller no longer needs the personal data for the
          purposes of the processing, but the data subject needs them in order to assert, exercise or defend legal
          claims.<br />
          The data subject has lodged an objection to the processing in accordance with Article 21 (1) of the DPA and it
          is not yet clear whether the legitimate reasons given by the controller outweigh those of the data subject.<br />
          If one of the above conditions is met and a data subject wishes to request the restriction of personal data
          stored at MICELOC, he/she may contact an employee of the data controller at any time. The MICELOC employee
          will initiate the restriction of the processing.<br />
        </div>

        <h6 class="font-700 f-20 text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          RIGHT TO DATA PORTABILITY
        </h6>
        <div class="my-5">
          Every person concerned by the processing of personal data has the right, granted by the European Directives
          and Regulations, to receive the personal data concerning him/her which have been provided by the data subject
          to a controller in a structured, common and machine-readable format. He/she also has the right to have this
          data communicated to another controller without hindrance by the controller to whom the personal data has been
          made available, provided that the processing is based on the consent pursuant to Art. 6(1)(a) DPA or Art.
          9(2)(a) DPA or on a contract pursuant to Art. 6(1)(b) DPA and that the processing is carried out by means of
          automated procedures, unless the processing is necessary for the performance of a task carried out in the
          public interest or in the exercise of official authority vested in the controller.<br /><br />
          Furthermore, when exercising their right to data transferability in accordance with Art. 20 Paragraph 1 FADP,
          the data subject has the right to obtain that personal data be transferred directly from one controller to
          another controller, insofar as this is technically feasible and provided that this does not adversely affect
          the rights and freedoms of other persons.<br /><br />
          In order to assert the right to data transferability, the data subject may contact a MICELOC employee at any
          time.<br /><br />
        </div>

        <h6 class="font-700 f-20 text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          RIGHT TO OBJECT
        </h6>
        <div class="my-5">
          Every person concerned by the processing of personal data has the right, granted by the European legislator
          for directives and regulations, to object at any time, for reasons arising from his or her particular
          situation, to the processing of personal data concerning him or her that is carried out on the basis of
          Article 6(1)(e) or (f) of the DPA. This also applies to profiling based on these provisions.<br /><br />
          In the event of an objection, MICELOC will no longer process the personal data unless we can demonstrate
          compelling legitimate reasons for processing that outweigh the interests, rights and freedoms of the data
          subject or the processing is necessary for the exercise or defence of legal claims.<br /><br />
          Where MICELOC processes personal data for the purpose of direct marketing, the data subject shall have the
          right to object, at any time, to the processing of personal data for the purpose of such marketing. This also
          applies to profiling, insofar as it is related to such direct marketing. If the data subject objects to
          MICELOC processing for the purposes of direct marketing, MICELOC will no longer process the personal data for
          these purposes.<br /><br />
          Furthermore, the data subject has the right to object, on grounds relating to his or her particular situation,
          to the processing of personal data concerning him or her being processed by MICELOC for scientific or
          historical research purposes or for statistical purposes in accordance with Article 89 (1) of the DPA, unless
          such processing is necessary for the performance of a task carried out in the public interest.<br /><br />
          In order to exercise the right of objection, the data subject may contact any MICELOC employee directly. The
          data subject is also free to exercise his/her right of objection in connection with the use of information
          society services, notwithstanding Directive 2002/58/EC, by means of automated procedures involving technical
          specifications.<br /><br />
        </div>

        <h6 class="font-700 f-20 text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          AUTOMATED DECISIONS IN INDIVIDUAL CASES INCLUDING PROFILING
        </h6>
        <div class="my-5">
          Every person concerned by the processing of personal data has the right, as granted by the European Directives
          and Regulations, not to be subject to a decision based solely on automated processing, including profiling,
          which produces legal effects concerning him or her or significantly affects him or her in a similar way,
          provided that the decision (1) is not necessary for the conclusion or performance of a contract between the
          data subject and the controller, or (2) is authorised by Union or national legislation to which the controller
          is subject and that such legislation provides for adequate safeguards of the rights and freedoms and
          legitimate interests of the data subject, or (3) is taken with the explicit consent of the data subject.<br /><br />
          Where the decision is (1) necessary for the conclusion or performance of a contract between the data subject
          and the controller, or (2) is taken with the express consent of the data subject, MICELOC shall take
          appropriate measures to safeguard the rights and freedoms and legitimate interests of the data subject,
          including at least the right to obtain the intervention of the controller, to put forward his point of view
          and to challenge the decision.<br /><br />
          If the data subject wishes to exercise rights relating to automated decisions, he or she may at any time
          contact a member of the staff of the controller.<br /><br />
        </div>

        <h6 class="font-700 f-20 text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          RIGHT TO REVOKE DATA PROTECTION CONSENT
        </h6>
        <div class="my-5">
          Every person affected by the processing of personal data has the right, granted by the European Directive and
          Regulation Giver, to revoke his or her consent to the processing of personal data at any time.<br /><br />
          If the data subject wishes to exercise his or her right to withdraw consent, he or she may at any time contact
          an employee of the controller.
          <br /><br />
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          PRIVACY POLICY ON THE USE AND APPLICATION OF GOOGLE ANALYTICS (WITH ANONYMIZATION)
        </h5>
        <div class="my-5">
          The person responsible for processing has integrated the Google Analytics component (with anonymisation
          function) on this website. Google Analytics is a web analysis service. Web analysis is the collection,
          collection and evaluation of data on the behaviour of visitors to websites. Among other things, a web analysis
          service collects data about which website a person concerned came to a website from (so-called referrers),
          which sub-pages of the website were accessed or how often and for how long a subpage was viewed. A web
          analysis is mainly used to optimize an internet page and for cost-benefit analysis of internet advertising.<br /><br />

          The operating company of the Google Analytics component is Google Inc, 1600 Amphitheatre Pkwy, Mountain View,
          CA 94043-1351, USA.
          <br /><br />

          The data controller uses the addition „_gat._anonymizeIp“ for web analysis via Google Analytics. By means of
          this addition, the IP address of the Internet connection of the person concerned is shortened by Google and
          made anonymous if the access to our Internet pages is from a member state of the European Union or from
          another state that is a party to the Agreement on the European Economic Area.<br /><br />

          The purpose of the Google Analytics component is to analyse the flow of visitors to our website. Among other
          things, Google uses the data and information obtained to evaluate the use of our website in order to compile
          online reports for us which show the activities on our website and to provide further services in connection
          with the use of our website.
          <br /><br />

          Google Analytics sets a cookie on the information technology system of the person concerned. What cookies are
          has already been explained above. By setting the cookie, Google is enabled to analyse the use of our website.
          Each time one of the individual pages of this website, which is operated by the person responsible for
          processing and on which a Google Analytics component has been integrated, is called up, the Internet browser
          on the information technology system of the person concerned is automatically prompted by the respective
          Google Analytics component to transmit data to Google for the purpose of online analysis. In the course of
          this technical process, Google receives knowledge of personal data, such as the IP address of the person
          concerned, which Google uses, among other things, to trace the origin of visitors and clicks and subsequently
          to enable commission settlements.
          <br /><br />

          By means of the cookie, personal information such as the access time, the location from which an access
          originated and the frequency of visits to our website by the person concerned is stored. Each time our website
          is visited, this personal data, including the IP address of the Internet connection used by the person
          concerned, is transmitted to Google in the United States of America. This personal data is stored by Google in
          the United States of America. Google may pass on this personal data collected via the technical process to
          third parties.
          <br /><br />

          The person concerned can prevent the setting of cookies by our website, as described above, at any time by
          means of a corresponding setting in the Internet browser used and thus permanently object to the setting of
          cookies. Such a setting of the Internet browser used would also prevent Google from setting a cookie on the
          information technology system of the person concerned. In addition, a cookie already set by Google Analytics
          can be deleted at any time via the Internet browser or other software programs.
          <br /><br />

          Furthermore, the data subject has the opportunity to object to and prevent the collection of data generated by
          Google Analytics and relating to the use of this website and the processing of this data by Google. For this
          purpose, the person concerned must download and install a browser add-on under the link
          <a
            @click="redirectToBlankPage('https://tools.google.com/dlpage/gaoptout')"
            class="font-500 text-underline color-primary"
            >https://tools.google.com/dlpage/gaoptout</a
          >
          . This browser add-on informs Google Analytics via JavaScript that no data and information about visits to
          websites may be transmitted to Google Analytics. The installation of the browser add-on is considered by
          Google to be an objection. If the data subject’s information technology system is deleted, formatted or
          reinstalled at a later date, the data subject must reinstall the browser add-on in order to deactivate Google
          Analytics. If the browser add-on is uninstalled or deactivated by the person concerned or another person
          within his or her sphere of control, the browser add-on may be reinstalled or reactivated.

          <br /><br />

          Further information and Google’s applicable privacy policy can be found at
          <a
            @click="redirectToBlankPage('https://www.google.de/intl/de/policies/privacy/')"
            class="font-500 text-underline color-primary"
            >https://www.google.de/intl/de/policies/privacy/</a
          >
          and
          <a
            @click="redirectToBlankPage('http://www.google.com/analytics/terms/de.html')"
            class="font-500 text-underline color-primary"
            >http://www.google.com/analytics/terms/de.html</a
          >

          . Google Analytics is explained in more detail at this link
          <a
            @click="redirectToBlankPage('https://www.google.com/intl/de_de/analytics/')"
            class="font-500 text-underline color-primary"
            >https://www.google.com/intl/de_de/analytics/</a
          >
          .
          <br /><br />
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          DATA PROTECTION REGULATIONS ON THE USE AND APPLICATION OF YOUTUBE
        </h5>
        <div class="my-5">
          The data controller has integrated YouTube components into this website. YouTube is an Internet video portal
          that allows video publishers to post video clips for free and other users to view, rate and comment on them,
          also free of charge. YouTube allows the publication of all types of videos, which is why complete film and
          television programmes, but also music videos, trailers or videos created by users themselves can be accessed
          via the Internet portal.<br /><br />

          YouTube is operated by YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. YouTube, LLC is a subsidiary
          of Google Inc, 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.<br /><br />

          Each time the data subject accesses one of the individual pages of this website, which is operated by the data
          controller and on which a YouTube component (YouTube video) has been integrated, the Internet browser on the
          data subject’s information technology system is automatically prompted by the respective YouTube component to
          download a display of the corresponding YouTube component from YouTube. Further information on YouTube can be
          found at
          <a
            @click="redirectToBlankPage('https://www.youtube.com/yt/about/de/')"
            class="font-500 text-underline color-primary"
            >https://www.youtube.com/yt/about/de/</a
          >

          . As part of this technical process, YouTube and Google are informed which specific subpage of our website is
          visited by the data subject.<br /><br />

          If the person concerned is logged in to YouTube at the same time, YouTube recognizes which specific page of
          our website the person concerned is visiting by calling up a page containing a YouTube video. This information
          is collected by YouTube and Google and assigned to the respective YouTube account of the person concerned.<br /><br />

          YouTube and Google receive information through the YouTube component that the data subject has visited our
          website whenever the data subject is logged on to YouTube at the same time as he or she visits our website,
          regardless of whether or not the data subject clicks on a YouTube video. If the data subject does not want
          this information to be sent to YouTube and Google, he or she can prevent it from being sent by logging out of
          his or her YouTube account before visiting our website.<br /><br />

          The privacy policy published by YouTube, which is available at
          <a
            @click="redirectToBlankPage('https://www.google.de/intl/de/policies/privacy/')"
            class="font-500 text-underline color-primary"
            >https://www.google.de/intl/de/policies/privacy/</a
          >

          , provides information about the collection, processing and use of personal data by YouTube and Google.
          <br /><br />
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          LEGAL BASIS OF THE PROCESSING
        </h5>
        <div class="my-5">
          Art. 6 I lit. a DS-GVO serves our company as a legal basis for processing operations for which we obtain
          consent for a specific processing purpose. If the processing of personal data is necessary for the performance
          of a contract to which the data subject is a party, as is the case, for example, with processing operations
          necessary for the supply of goods or the provision of another service or consideration, the processing is
          based on Art. 6 I lit. b DS-GVO. The same applies to such processing operations which are necessary for the
          implementation of pre-contractual measures, for example in cases of enquiries about our products or services.
          If our company is subject to a legal obligation which makes it necessary to process personal data, for example
          to fulfil tax obligations, the processing is based on Art. 6 I lit. c DS-GVO. In rare cases, the processing of
          personal data may be necessary to protect the vital interests of the data subject or another natural person.
          This would be the case, for example, if a visitor to our company was injured and his or her name, age, health
          insurance details or other vital information had to be disclosed to a doctor, hospital or other third party.
          The processing would then be based on Art. 6 I lit. d DS-GVO. Finally, processing operations could be based on
          Art. 6 I lit. f DS-GVO. Processing operations which are not covered by any of the above legal bases are based
          on this legal basis if the processing is necessary to safeguard a legitimate interest of our company or of a
          third party, provided that the interests, fundamental rights and freedoms of the data subject do not prevail.
          We are permitted to carry out such processing operations in particular because they have been specifically
          mentioned by the European legislator. In this respect, it took the view that a legitimate interest could be
          assumed if the data subject is a customer of the controller (Recital 47 sentence 2 DS-GVO).
          <br /><br />
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          LEGITIMATE INTERESTS IN PROCESSING PURSUED BY THE CONTROLLER OR A THIRD PARTY
        </h5>
        <div class="my-5">
          If the processing of personal data is based on Article 6 I lit. f DS-GVO, our legitimate interest is to carry
          out our business activities for the benefit of the well-being of all our employees and our shareholders.
          <br /><br />
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          THE PERIOD FOR WHICH THE PERSONAL DATA ARE STORED
        </h5>
        <div class="my-5">
          The criterion for the duration of storage of personal data is the respective legal retention period. After
          expiry of this period, the corresponding data is routinely deleted, provided that it is no longer required for
          the fulfilment of the contract or the initiation of a contract.
          <br /><br />
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          LEGAL OR CONTRACTUAL PROVISIONS ON THE PROVISION OF PERSONAL DATA; NECESSITY FOR THE CONCLUSION OF THE
          CONTRACT; OBLIGATION OF THE DATA SUBJECT TO PROVIDE THE PERSONAL DATA; POSSIBLE CONSEQUENCES OF NOT PROVIDING
          THE DATA
        </h5>
        <div class="my-5">
          We would like to inform you that the provision of personal data is partly required by law (e.g. tax
          regulations) or can also result from contractual regulations (e.g. information on the contractual partner).
          Sometimes it may be necessary for a contract to be concluded that a data subject provides us with personal
          data, which must subsequently be processed by us. For example, the person concerned is obliged to provide us
          with personal data if our company concludes a contract with him/her. Failure to provide the personal data
          would mean that the contract with the person concerned could not be concluded. Before the person concerned
          makes personal data available to us, the person concerned must contact one of our employees. Our employee will
          inform the data subject on a case-by-case basis whether the provision of the personal data is required by law
          or contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the
          personal data and what the consequences would be if the personal data were not provided.
          <br /><br />
        </div>

        <h5 class="headline-bold text-left" :class="{ 'headline-xs': $vuetify.breakpoint.xsOnly }">
          EXISTENCE OF AN AUTOMATED DECISION MAKING PROCESS
        </h5>
        <div class="my-5">
          As a responsible company, we avoid automatic decision making or profiling.<br /><br />
          This data protection declaration was created by the Data Protection Declaration Generator of the DGD Deutsche
          Gesellschaft für Datenschutz GmbH, which acts as External Data Protection Officer Dortmund, in cooperation
          with the Cologne-based data protection lawyer Christian Solmecke. <br /><br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  auth: false,
  methods: {
    acceptPolicy() {
      this.dialog = false;
    },
    redirectToBlankPage(url) {
      window.open(url, "_blank");
    }
  }
};
</script>
<style scoped>
.bg-white {
  background-color: #fff;
}
</style>
